// Generated by Framer (c76752e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/sDRbYzy5T";

const enabledGestures = {ewD7hiyIb: {hover: true}};

const cycleOrder = ["ewD7hiyIb", "bjuVOn22f"];

const serializationHash = "framer-QRsvq"

const variantClassNames = {bjuVOn22f: "framer-v-1li3sav", ewD7hiyIb: "framer-v-kqvzbr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Default: "ewD7hiyIb", Off: "bjuVOn22f"}

const getProps = ({button, color, height, id, link, mouseEnter, mouseLeave, width, ...props}) => { return {...props, cAcWdPffr: mouseLeave ?? props.cAcWdPffr, eVQipTgv0: mouseEnter ?? props.eVQipTgv0, nTkvqfF5n: link ?? props.nTkvqfF5n, nXbLkWflA: button ?? props.nXbLkWflA ?? "Button", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ewD7hiyIb", xZgDKetz4: color ?? props.xZgDKetz4 ?? "var(--token-dade266e-403f-4443-9daf-d9d6dfc8a9db, rgb(33, 33, 33))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;button?: string;link?: string;color?: string;mouseEnter?: any;mouseLeave?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nXbLkWflA, nTkvqfF5n, xZgDKetz4, eVQipTgv0, cAcWdPffr, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ewD7hiyIb", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntercnzqx8 = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (eVQipTgv0) {const res = await eVQipTgv0(...args);
if (res === false) return false;}
})

const onMouseLeavegoqfyj = activeVariantCallback(async (...args) => {
setGestureState({isHovered: false})
if (cAcWdPffr) {const res = await cAcWdPffr(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={nTkvqfF5n} motionChild nodeId={"ewD7hiyIb"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-kqvzbr", className, classNames)} framer-1y52tuk`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"ewD7hiyIb"} onMouseEnter={onMouseEntercnzqx8} onMouseLeave={onMouseLeavegoqfyj} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"ewD7hiyIb-hover": {opacity: 1}, bjuVOn22f: {opacity: 0.5}}} {...addPropertyOverrides({"ewD7hiyIb-hover": {"data-framer-name": undefined}, bjuVOn22f: {"data-framer-name": "Off"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-v33mbh"} data-styles-preset={"sDRbYzy5T"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-xZgDKetz4-vFyzSfmIX))"}}>About Us</motion.p></React.Fragment>} className={"framer-1hbsc5x"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"tSW1yrIsc"} style={{"--extracted-r6o4lv": "var(--variable-reference-xZgDKetz4-vFyzSfmIX)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-xZgDKetz4-vFyzSfmIX": xZgDKetz4}} text={nXbLkWflA} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-2efx4z"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"Yn0Dd69VD"} style={{backgroundColor: xZgDKetz4, opacity: 0.5}}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QRsvq.framer-1y52tuk, .framer-QRsvq .framer-1y52tuk { display: block; }", ".framer-QRsvq.framer-kqvzbr { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-QRsvq .framer-1hbsc5x { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-QRsvq .framer-2efx4z { bottom: 0px; flex: none; height: 1px; left: 0px; overflow: visible; position: absolute; width: 0%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QRsvq.framer-kqvzbr { gap: 0px; } .framer-QRsvq.framer-kqvzbr > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-QRsvq.framer-kqvzbr > :first-child { margin-top: 0px; } .framer-QRsvq.framer-kqvzbr > :last-child { margin-bottom: 0px; } }", ".framer-QRsvq.framer-v-1li3sav.framer-kqvzbr { cursor: unset; }", ".framer-QRsvq.framer-v-kqvzbr.hover .framer-2efx4z { width: 100%; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"bjuVOn22f":{"layout":["auto","auto"]},"yCh8ekygI":{"layout":["auto","auto"]}}}
 * @framerVariables {"nXbLkWflA":"button","nTkvqfF5n":"link","xZgDKetz4":"color","eVQipTgv0":"mouseEnter","cAcWdPffr":"mouseLeave"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervFyzSfmIX: React.ComponentType<Props> = withCSS(Component, css, "framer-QRsvq") as typeof Component;
export default FramervFyzSfmIX;

FramervFyzSfmIX.displayName = "Text";

FramervFyzSfmIX.defaultProps = {height: 24, width: 64};

addPropertyControls(FramervFyzSfmIX, {variant: {options: ["ewD7hiyIb", "bjuVOn22f"], optionTitles: ["Default", "Off"], title: "Variant", type: ControlType.Enum}, nXbLkWflA: {defaultValue: "Button", displayTextArea: false, title: "Button", type: ControlType.String}, nTkvqfF5n: {title: "Link", type: ControlType.Link}, xZgDKetz4: {defaultValue: "var(--token-dade266e-403f-4443-9daf-d9d6dfc8a9db, rgb(33, 33, 33)) /* {\"name\":\"Black\"} */", title: "Color", type: ControlType.Color}, eVQipTgv0: {title: "Mouse Enter", type: ControlType.EventHandler}, cAcWdPffr: {title: "Mouse Leave", type: ControlType.EventHandler}} as any)

addFonts(FramervFyzSfmIX, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})